import React from "react"
import styled from "styled-components"
import Typist from "react-typist"

const Logo = () => {
  const Box = styled.div`
    position: absolute;
    width: 200px;
    height: 200px;
    background-color: #bfa140;
    z-index: 1;
  `

  const Text = styled.span`
    display: inline-block;
    position: relative;
    z-index: 2;
    .p,
    .s {
      color: black;
      font-size: 100px;
      line-height: 100px;
    }
    .p {
    margin-right: 6px;
    }
    .s {
    margin-right: 10px;
    }
  `

  return (
    <div
      style={{ display: `flex`, justifyContent: `center`, userSelect: `none`, transform: `scale(.85)` }}
    >
      <div
        style={{
          position: `relative`,
          display: `flex`,
          flexDirection: `column`,
          textAlign: `left`,
        }}
      >
        <Box/>

        <Typist className={`name`}>
          <h1>
            <Text>
              <span className={`p`}>P</span>atrik
            </Text>
            <br/>
            <Text>
              <span className={`s`}>S</span>zewczyk
            </Text>
          </h1>
        </Typist>
      </div>
    </div>
  )
}

export default Logo
