import React from "react"
import PropTypes from "prop-types"
import Main from "./styled/main"
import "../style/style.css"

const Layout = ({ children }) => <Main>{children}</Main>

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
