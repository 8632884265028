import React from "react"
import Arrow from "../arrow"
import Logo from "../logo"

const About = ({ api, activeSection }) => (
  <section className={`section section1`}>
    <Logo/>
    <Arrow direction={`down`} api={api}/>
  </section>
)

export default About

// const getAge = birthday => {
//   const ageDifMs = Date.now() - birthday.getTime()
//   const ageDate = new Date(ageDifMs)
//   return Math.abs(ageDate.getUTCFullYear() - 1970)
// }
//
// const dateOfBirth = new Date(1997, 8, 30)
