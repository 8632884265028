import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const Main = ({ children }) => {
  const Element = styled.main`
    background: black;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    width: 100%;
    height: 100vh;
  `

  return <Element>{children}</Element>
}

Main.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Main
