import React from "react"
import Social from "../social"
import Arrow from "../arrow"
import Footer from "../styled/footer"
import Typist from "react-typist"

const Contact = ({ api, activeSection }) => {
  return (
    <section className={`section`}>
      <div className="container">
        <div className="wrap row">
          <Arrow direction={`up`} api={api}/>

          <div className="box">
            <a href={`mailto:patrik.szewczyk@gmail.com`}>
              <Typist>{`patrik.szewczyk@gmail.com`}</Typist>
            </a>
            <Social/>

            <Footer/>
          </div>
        </div>
      </div>
    </section>
  )
}
export default Contact
