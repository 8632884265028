import React from "react"
import styled from "styled-components"

const Footer = () => {
  const Element = styled.footer`
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 10px;
    color: #ababab;
    font-size: 12px;
    a {
      border: none;
      color: #ababab;
      &:hover,
      &:focus {
        border: none;
        color: #ababab;
      }
    }
  `

  return (
    <Element>
      © {new Date().getFullYear()}{" "}
      <a href="mailto:patrik.szewczyk@gmail.com"> Patrik Szewczyk</a>
    </Element>
  )
}

export default Footer
