import React from "react"
import styled from "styled-components"
import LinkedIn from "../images/linkedin.svg"
import Facebook from "../images/facebook.svg"

const Social = () => {
  const Element = styled.div`
    position: absolute;
    margin-left: auto;
    margin-right: auto;
    left: 0;
    right: 0;
    bottom: 50px;
    * {
      pointer-events: fill;
    }

    a {
      border: none !important;
      margin: 5px;
      svg {
        path {
          fill: #bfa140;
        }
      }
      svg:hover path {
        fill: white;
      }
    }
  `

  return (
    <Element>
      <a
        href="https://www.facebook.com/thepatriczek"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Facebook"
      >
        <Facebook/>
      </a>
      <a
        href="https://www.linkedin.com/in/thepatriczek/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="LinkedIn"
      >
        <LinkedIn/>
      </a>
    </Element>
  )
}
export default Social
