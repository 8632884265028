import React from "react"

const Arrow = ({ api, direction }) => (
  <div
    className={direction === `up` ? `arrowUp` : `arrowDown`}
    onClick={e => {
      e.preventDefault()

      if (direction === `up`) {
        api.moveSectionUp()
      } else {
        api.moveSectionDown()
      }
    }}
  >
    <div className={direction === `up` ? `arrowUp__link` : `arrowDown__link`}>
      <span
        className={
          direction === `up`
            ? `arrowUp__link__line arrowUp__link__line--first`
            : `arrowDown__link__line arrowDown__link__line--first`
        }
      />
    </div>
  </div>
)

export default Arrow
