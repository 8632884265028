import React from "react"
import Arrow from "../arrow"
import Moravio from "../images/moravio"
import Manolo from "../images/manolo"
import Atlas from "../images/atlas"

const Work = ({ api }) => {
  return (
    <section className={`section`}>
      <Arrow direction={`up`} api={api}/>

      <div className="slide" data-anchor="slide1">
        <div
          style={{ width: `200px`, height: `200px`, display: `inline-block` }}
        >
          <a
            href={`https://www.atlasconsulting.cz/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Atlas/>
          </a>
        </div>

        <h4>
          <a
            href={`https://www.atlasconsulting.cz/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {`Atlas Consulting spol. s.r.o.`}
          </a>
        </h4>

        <div>
          {`1. 11. 2017 -`} <strong>{`present`}</strong>
        </div>
      </div>
      <div className="slide" data-anchor="slide2">
        <div
          style={{ width: `200px`, height: `200px`, display: `inline-block` }}
        >
          {" "}
          <a
            href={`https://www.moravio.com/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Moravio/>
          </a>
        </div>
        <h4>
          <a
            href={`https://www.moravio.com/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {`Moravio`}
          </a>
        </h4>

        <div>
          {`1. 9. 2015`} - <span>{`31. 10. 2017`}</span>
        </div>

        <p>{`PHP, Wordpress, JavaScript, React, Redux, SASS/SCSS, LESS`}</p>
      </div>

      <div className="slide" data-anchor="slide3">
        <div
          style={{ width: `200px`, height: `200px`, display: `inline-block` }}
        >
          {" "}
          <a
            href={`http://www.manolodesign.cz/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Manolo/>
          </a>
        </div>
        <h4>
          <a
            href={`http://www.manolodesign.cz/`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {`Manolodesign`}
          </a>
        </h4>

        <div>
          {`1. 9. 2013`} - <span>{`1. 6. 2015`}</span>
        </div>

        <p>{`HTML5, CSS3, LESS, Bootstrap`}</p>
      </div>

      <Arrow direction={`down`} api={api}/>
    </section>
  )
}
export default Work
