import React from "react"
import ReactFullpage from "@fullpage/react-fullpage"
import About from "./sections/about"
import Work from "./sections/work"
import Contact from "./sections/contact"

const Fullpage = () => (
  <ReactFullpage
    render={({ state, fullpageApi }) => {
      return (
        <ReactFullpage.Wrapper>
          <About api={fullpageApi}/>
          <Work api={fullpageApi}/>
          <Contact api={fullpageApi}/>
        </ReactFullpage.Wrapper>
      )
    }}
  />
)

export default Fullpage
