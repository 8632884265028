import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Fullpage from "../components/fullpage"
import "react-typist/dist/Typist.css"
import Loader from "../components/styled/Loader"

console.error = () => null

export default class IndexPage extends React.Component {
  constructor(_) {
    super(_)
    this.state = {
      isLoading: true,
    }
  }

  componentDidMount() {
    this.setState({
      isLoading: false,
    })
  }

  render() {
    return (
      <Layout>
        {this.state.isLoading && <Loader/>}

        <SEO
          keywords={[
            `Patrik Szewczyk`,
            `Portfolio`,
            `szewczyk.cz`,
            `Javascript`,
            `Typescript`,
            `React`,
          ]}
        />
        <Fullpage/>
      </Layout>
    )
  }
}
