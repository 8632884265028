import React from "react"
import styled from "styled-components"

const Loader = () => {
  const Element = styled.main`
    position: fixed;
    width: 100%;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    background: black;
    z-index: 9999;
    color: #bfa140;
  `

  return <Element>Loading...</Element>
}

export default Loader
